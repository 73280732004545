import { PlatformRole } from "@hex/common";
import React from "react";

import { HexMenuItem } from "../../hex-components";
import { CurrentUser } from "../../hooks/me/useCurrentUser";
import { useOnClickProps } from "../../hooks/useOnClickProps";
import { Routes } from "../../route/routes";
import { CyData } from "../../util/cypress";
import { GlobeIcon } from "../icons/CustomIcons";

interface AdminMenuItemsProps {
  currentUser: CurrentUser;
}

export const AdminMenuItems: React.FunctionComponent<AdminMenuItemsProps> = ({
  currentUser,
}) => {
  const isPlatformAdmin = currentUser.platformRole === PlatformRole.ADMIN;

  const platformAdminProps = useOnClickProps({
    to: Routes.ADMIN_PLATFORM.getUrl({}),
  });

  return (
    <>
      {isPlatformAdmin && (
        <HexMenuItem
          data-cy={CyData.PLATFORM_ADMINISTRATION}
          icon={<GlobeIcon />}
          text="Platform Administration"
          {...platformAdminProps}
        />
      )}
    </>
  );
};
