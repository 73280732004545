import { gql } from "@apollo/client";
import { Popover2InteractionKind } from "@blueprintjs/popover2";
import { KernelSize } from "@hex/common";
import filesize from "filesize";
import React, { useEffect } from "react";
import styled from "styled-components";

import { HexTooltip } from "../../hex-components/HexTooltip";
import { useHexFlag } from "../../util/useHexFlags.js";
import { Link } from "../common/DocsLink";
import { Picker } from "../common/Picker";
import { FeatureGatePill } from "../feature-gate/FeatureGatePill";
import { FeatureGateToolTip } from "../feature-gate/FeatureGateToolTip";

import { useGetKernelSizesForPickerQuery } from "./KernelSizePicker.generated";

gql`
  query GetKernelSizesForPicker {
    getKernelSizesV2 {
      options {
        id
        humanName
        memoryLimit
        cpuLimit
        disabled
        gpuLimit
      }
      default
      isFreeTrial
    }
  }
`;

const Description = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

interface KernelSizePickerProps {
  currentKernelSize: KernelSize | null;
  onSelect: (newKernelSize: KernelSize) => void;
  disabled?: boolean;
  hideLabel?: boolean; // defaults to false
  smallPicker?: boolean; // defaults to true
}

export const KernelSizePicker: React.FunctionComponent<
  KernelSizePickerProps
> = ({
  currentKernelSize,
  disabled,
  hideLabel = false,
  onSelect,
  smallPicker = true,
}) => {
  // We want this to be here to auto reload the UI if we change this FF
  const maxBetaKernelSizes = JSON.stringify(useHexFlag("max-beta-kernel-size"));

  const {
    data,
    error: error_,
    loading,
    refetch,
  } = useGetKernelSizesForPickerQuery({});

  useEffect(() => {
    void refetch();
  }, [maxBetaKernelSizes, refetch]);

  const maybeDefaultKernelSizeResult = KernelSize.validate(
    data?.getKernelSizesV2?.default,
  );

  const defaultSize = maybeDefaultKernelSizeResult.success
    ? maybeDefaultKernelSizeResult.value
    : null;

  const kernelSizesOrEmpty = data?.getKernelSizesV2?.options ?? [];

  const upgradeComputeFeatureGate = !data?.getKernelSizesV2?.isFreeTrial ? (
    <FeatureGateToolTip
      content="to use larger kernels"
      featureGate="maxKernelSizes"
    >
      <FeatureGatePill />
    </FeatureGateToolTip>
  ) : (
    <HexTooltip
      content={
        <>
          We limit the maximum kernels size on free trials. You can{" "}
          <Link href="mailto:sales@hex.tech?subject=Requesting removal of kernel size limit">
            reach out to us
          </Link>{" "}
          to request larger sizes.
        </>
      }
      interactionKind={Popover2InteractionKind.HOVER}
    >
      <FeatureGatePill />
    </HexTooltip>
  );

  const error = !!error_ || kernelSizesOrEmpty.length === 0;

  return (
    <Picker
      disabled={disabled}
      error={error}
      items={[...kernelSizesOrEmpty]
        // sort first by GPUs and then Memory
        .sort(
          (a, b) => b.gpuLimit - a.gpuLimit || b.memoryLimit - a.memoryLimit,
        )
        .map((kernelSize) => ({
          title: kernelSize.humanName,
          disabled: kernelSize.disabled,
          rightElement:
            kernelSize.disabled && !disabled && upgradeComputeFeatureGate,

          description: (
            <Description>
              <div>{filesize(kernelSize.memoryLimit)} memory</div>
              <div>
                {kernelSize.cpuLimit === 1
                  ? `${kernelSize.cpuLimit} CPU`
                  : `${kernelSize.cpuLimit} CPUs`}
              </div>
              {kernelSize.gpuLimit > 0 ? (
                <div>
                  {kernelSize.gpuLimit === 1
                    ? `${kernelSize.gpuLimit} GPU`
                    : `${kernelSize.gpuLimit} GPUs`}
                </div>
              ) : null}
            </Description>
          ),
          key: kernelSize.id,
        }))}
      label={!hideLabel ? "Compute profile" : ""}
      loading={loading}
      selectedItem={currentKernelSize ?? defaultSize}
      smallPicker={smallPicker}
      onSelect={onSelect}
    />
  );
};
